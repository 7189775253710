import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Experiments = () => (
  <Layout>
    <Seo
      title="Experiments"
      keywords={[`gatsby`, `lester lee`, `devops`, `back-end`, `full-stack`]}
    />
    Experiments Here!!
  </Layout>
)

export default Experiments
